import React from "react"
import { Link } from "gatsby"
import { Button, Container } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import Logo from "../../assets/img/Logo.svg"

const Footer = props => {
  return (
    <>
      <footer className={props.classes} data-background-color="black">
        <Container className="d-flex align-items-center justify-content-between">
          <Link className="footer-brand" to="/de">
            <img src={Logo} alt="Logo Morska 5" width={215} />
          </Link>
          <ul className="pull-center">
            <li>
              <Link to="/de/regulamin">Geschäftsbedingungen der Webseite</Link>
            </li>
            <li>
              <Link to="/de/polityka-prywatnosci">Datenschutzbestimmungen</Link>
            </li>
          </ul>
          <ul className="social-buttons pull-right">
            <li>
              <Button
                className="btn-icon btn-neutral btn-sea-hover-color d-flex align-items-center"
                color="link"
                href="https://www.facebook.com/Morska-5-pokojeapartamenty-102339041559896/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </Button>
            </li>
            <li>
              <Button
                className="btn-icon btn-neutral btn-sea-hover-color d-flex align-items-center"
                color="link"
                href="https://www.instagram.com/morska5/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </Button>
            </li>
          </ul>
        </Container>
      </footer>
    </>
  )
}

export default Footer
