import React from "react"
import Layout from "../components/layout.de"
import SEO from "../components/seo"
import SmallHeader from "../components/SmallHeader"
import { Col, Container, Row } from "reactstrap"
import TitleBlock from "../components/blocks/TitleBlock"
import ContactInfo from "../components/general/ContactInfo"
import { graphql, useStaticQuery } from "gatsby"

const Cennik = () => {
  const images = useStaticQuery(graphql`
    {
      cover: file(
        name: { eq: "general-page-background" }
        relativeDirectory: { eq: "headers" }
      ) {
        id
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Preisliste für die Saison 2025" lang="de" />
      <SmallHeader title="Preisliste" backgroundName={images.cover} />

      <Container>
        <Row>
          <Col xs="12" md="12" className="mr-auto ml-auto">
            <div className="text-center section">
              <TitleBlock
                title="Preisliste für die Saison 2025"
                headingLevel="h3"
                extendedClasses="color-sea font-weight-bold"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="8" className="mr-auto ml-auto">
            <div className={`table-responsive`}>
              <table className={`table price-table`}>
                <thead>
                  <tr>
                    <th></th>
                    <th>06.06 - 30.06, 1.09 - 14.09</th>
                    <th>01.07 - 31.08</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Appartement für 2 Personen</td>
                    <td className={`text-center`}>250PLN/Nacht</td>
                    <td className={`text-center`}>300PLN/Nacht</td>
                  </tr>

                  <tr>
                    <td>Appartement für 3 Personen</td>
                    <td className={`text-center`}>280PLN/Nacht</td>
                    <td className={`text-center`}>350PLN/Nacht</td>
                  </tr>

                  <tr>
                    <td>Appartement für 3 Personen (2 Zimmer)</td>
                    <td className={`text-center`}>320PLN/Nacht</td>
                    <td className={`text-center`}>380PLN/Nacht</td>
                  </tr>

                  <tr>
                    <td>Appartement für 4 Personen (2 Zimmer)</td>
                    <td className={`text-center`}>380PLN/Nacht</td>
                    <td className={`text-center`}>450PLN/Nacht</td>
                  </tr>

                  <tr>
                    <td>Appartement für 5 Personen (2 Zimmer)</td>
                    <td className={`text-center`}>410PLN/Nacht</td>
                    <td className={`text-center`}>480PLN/Nacht</td>
                  </tr>

                  <tr>
                    <td>
                      Stellplatz in der Garage (frühere Buchung erforderlich)
                    </td>
                    <td className={`text-center`}>10PLN/Nacht</td>
                    <td className={`text-center`}>20PLN/Nacht</td>
                  </tr>

                  <tr>
                    <td>Parkplatz (frühere Buchung erforderlich)</td>
                    <td className={`text-center`}>kostenlos</td>
                    <td className={`text-center`}>10PLN/Nacht</td>
                  </tr>
                  <tr>
                    <td>Zusätzliches Bett für Kinder bis 3 Jahre</td>
                    <td colSpan={`2`} className={`text-center`}>
                      kostenlos
                    </td>
                  </tr>
                  <tr>
                    <td>Extra bed for children over 4 years and adults</td>
                    <td colSpan={`2`} className={`text-center`}>
                      50PLN/Nacht
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Babybett mit Bettwäsche (frühere Buchung erforderlich)
                    </td>
                    <td colSpan={`2`} className={`text-center`}>
                      50 PLN (einmalige Gebühr)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Babystuhl, Babybadewanne (frühere Buchung erforderlich)
                    </td>
                    <td colSpan={`2`} className={`text-center`}>
                      kostenlos
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Row>
              <Col md="12">
                <p className="pt-5">
                  <span className="color-sea font-weight-bold">Check-in:</span>{" "}
                  erfolgt ab 16:00 Uhr des ersten Aufenthaltstags und Check-out
                  bis 10:00 Uhr des letzten Aufenthaltstages.
                </p>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <p className="pt-5 font-weight-bold">
                  Haustiere sind im Hotel nicht erlaubt.
                </p>
              </Col>
            </Row>

          </Col>
        </Row>

        <Row>
          <Col xs="12" md="12" className="ml-auto mr-auto">
            <div className="section">
              <ContactInfo />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Cennik
